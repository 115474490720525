import { render, staticRenderFns } from "./TipPad.vue?vue&type=template&id=4e475f1d&scoped=true&"
import script from "./TipPad.vue?vue&type=script&lang=js&"
export * from "./TipPad.vue?vue&type=script&lang=js&"
import style0 from "./TipPad.vue?vue&type=style&index=0&id=4e475f1d&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_svgbhp6mq6cpqeg674bwlepigi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e475f1d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/.pnpm/vuetify-loader@1.7.3_jyn3ljfz5khtam3igvcs7jnv2i/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VDialog,VIcon,VSlider,VSpacer,VToolbar,VToolbarItems})
