import { render, staticRenderFns } from "./InvoiceBalance.vue?vue&type=template&id=2d925430&scoped=true&"
import script from "./InvoiceBalance.vue?vue&type=script&lang=js&"
export * from "./InvoiceBalance.vue?vue&type=script&lang=js&"
import style0 from "./InvoiceBalance.vue?vue&type=style&index=0&id=2d925430&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_svgbhp6mq6cpqeg674bwlepigi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d925430",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/.pnpm/vuetify-loader@1.7.3_jyn3ljfz5khtam3igvcs7jnv2i/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
