import { render, staticRenderFns } from "./BottomNav.vue?vue&type=template&id=7e65fe44&"
import script from "./BottomNav.vue?vue&type=script&lang=js&"
export * from "./BottomNav.vue?vue&type=script&lang=js&"
import style0 from "./BottomNav.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_svgbhp6mq6cpqeg674bwlepigi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/.pnpm/vuetify-loader@1.7.3_jyn3ljfz5khtam3igvcs7jnv2i/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBottomNavigation,VBtn,VIcon})
